import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../../components/Navbar/Navbar";
import {
  useToast,
  Box,
  Flex,
  Select,
  Button,
  FormControl,
  Input,
} from "@chakra-ui/react";

import { UserContext } from "../../../contexts/User";

export default function CreateClient() {
  const { createClientApi } = useContext(UserContext);
  const toast = useToast();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("");
  const [nationality, setNationality] = useState("");
  const [address, setAddress] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [institution, setInstitution] = useState("");
  const [institutionType, setInstitutionType] = useState("");
  const [department, setDepartment] = useState("");
  const [graduate, setGraduate] = useState("");
  const [laboratory, setLaboratory] = useState("");
  const [lattes, setLattes] = useState("");

  const handleInputNameChange = (e) => setName(e.target.value);
  const handleInputEmailChange = (e) => setEmail(e.target.value);
  const handleInputCpfChange = (e) => setCpf(e.target.value);
  const handleInputNationalityChange = (e) => setNationality(e.target.value);
  const handleInputAddressChange = (e) => setAddress(e.target.value);
  const handleInputCityChange = (e) => setCity(e.target.value);
  const handleInputStateChange = (e) => setState(e.target.value);
  const handleInputCountryChange = (e) => setCountry(e.target.value);
  const handleInputZipcodeChange = (e) => setZipcode(e.target.value);
  const handleInputInstitutionChange = (e) => setInstitution(e.target.value);
  const handleInputInstitutionTypeChange = (e) =>
    setInstitutionType(e.target.value);
  const handleInputDepartmentChange = (e) => setDepartment(e.target.value);
  const handleInputLaboratoryChange = (e) => setLaboratory(e.target.value);
  const handleInputGraduateChange = (e) => setGraduate(e.target.value);
  const handleInputLattesChange = (e) => setLattes(e.target.value);
  const navigate = useNavigate();

  async function createClient() {
    const data = {
      name: name,
      email: email,
      cpf: cpf,
      nationality: nationality,
      zipcode: zipcode,
      whatsapp: "",
      street_address: address,
      city: city,
      state: state,
      country: country,
      institution_name: institution,
      institution_type: institutionType,
      department_name: department,
      laboratory_name: laboratory,
      link_lattes: lattes,
    };
    try {
      const newClient = await createClientApi(data);
      if (newClient.status === 200) {
        toast({
          title: "Usuário Criado com Sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      navigate("/clientes");
    } catch (error) {
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
      console.log(error);
    }
  }

  useEffect(() => {}, []);

  return (
    <>
      <Box>
        <Navbar />
      </Box>

      <Flex ml={250} display={"flex"} justifyContent={"center"}>
        <Box
          m={"30px"}
          marginBottom={5}
          width={"70%"}
          boxShadow="xl"
          padding={35}
          borderRadius={15}
          backgroundColor={"white"}
        >
          <h2>Adicionar um novo cliente</h2>
          <Box>
            <FormControl display={"inline-flex"}>
              <Input
                width={"50%"}
                type="name"
                placeholder="Nome Completo"
                onChange={handleInputNameChange}
              />
              <Input
                placeholder="Digite o CPF"
                width={"40%"}
                marginLeft={"10%"}
                onChange={handleInputCpfChange}
              />
            </FormControl>
            <FormControl marginTop={"2%"}>
              <Input
                placeholder="Digite seu e-mail"
                onChange={handleInputEmailChange}
              />
            </FormControl>

            <h2>Endereço</h2>
            <FormControl display={"inline-flex"} marginTop={"2%"}>
              <Input
                width={"30%"}
                type="text"
                placeholder="Nacionalidade"
                onChange={handleInputNationalityChange}
              />
              <Input
                placeholder="Cidade"
                width={"20%"}
                marginLeft={"2%"}
                onChange={handleInputCityChange}
              />
              <Input
                placeholder="Estado"
                width={"15%"}
                marginLeft={"2%"}
                onChange={handleInputStateChange}
              />
              <Input
                placeholder="País"
                width={"15%"}
                marginLeft={"2%"}
                onChange={handleInputCountryChange}
              />
            </FormControl>

            <FormControl display={"inline-flex"} marginTop={"2%"}>
              <Input
                width={"30%"}
                type="text"
                placeholder="Endereço"
                onChange={handleInputAddressChange}
              />
              <Input
                placeholder="CEP"
                width={"15%"}
                marginLeft={"2%"}
                onChange={handleInputZipcodeChange}
              />
            </FormControl>
            <h2>Outras informações</h2>
            <FormControl display={"inline-flex"} marginTop={"2%"}>
              <Input
                width={"60%"}
                type="text"
                placeholder="Intituto"
                onChange={handleInputInstitutionChange}
              />
              <Select
                placeholder="Tipo de instituição"
                width={"20%"}
                marginLeft={"2%"}
                onChange={handleInputInstitutionTypeChange}
              >
                <option>Público</option>
                <option>Privado</option>
              </Select>
              <Input
                width={"20%"}
                type="text"
                placeholder="Departamento"
                marginLeft={"2%"}
                onChange={handleInputDepartmentChange}
              />
            </FormControl>
            <FormControl display={"inline-flex"} marginTop={"2%"}>
              <Input
                width={"30%"}
                type="text"
                placeholder="Nome do Laboratório"
                onChange={handleInputLaboratoryChange}
              />
              <Select
                placeholder="Tipo de Graduação"
                width={"30%"}
                marginLeft={"2%"}
                onChange={handleInputGraduateChange}
              >
                <option>Doutorado</option>
                <option>Mestrado</option>
              </Select>
            </FormControl>
            <FormControl marginTop={"2%"}>
              <Input
                width={"20%"}
                type="text"
                placeholder="Link do Lattes "
                onChange={handleInputLattesChange}
              />
            </FormControl>
          </Box>
          <Box marginTop={"2%"}>
            <Button margin={"2%"}>
              {" "}
              <Link to="/clientes"> Voltar </Link>
            </Button>

            <Button colorScheme={"green"} onClick={() => createClient()}>
              Salvar
            </Button>
          </Box>
        </Box>
      </Flex>
    </>
  );
}
