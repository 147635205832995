import React from "react";
import api from "../services/api";

export const OrderContext = React.createContext({});

export const OrderProvider = ({ children }) => {
  const listOrdersApi = async (params) => {
    const token = localStorage.getItem("token"); 
    const response = await api.get("/orders", {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params
    });
    return response;
  };

  const listOrdersExpertApi = async (params) => {
    const token = localStorage.getItem("token");
    const response = await api.get("/orders", {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const createOrderApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("/orders", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const listExpertsStepsApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/expert/steps/order/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const getOrderInfo = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/orders/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const getOrderFeedbackApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/orders/${id}/feedbacks`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const showExpertOrderFeedbackApi = async (userID, orderID) => {
    const token = localStorage.getItem("token");
    const response = await api.get(
      `/orders/${orderID}/feedbacks/${userID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  };

  const listOrderStepsApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/expert/steps/order/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const answerOrderApi = async (id, data) => {
    const token = localStorage.getItem("token");
    const response = await api.post(`/answer/invite/expert/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const sendApproveOrderApi = async (data, orderID) => {
    const token = localStorage.getItem("token");
    const response = await api.post(`/orders/${orderID}/send-approve`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const approveOrderApi = async (data, id) => {
    const token = localStorage.getItem("token");
    const response = await api.post(`/orders/${id}/approve`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const feedbackQuestionsApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/feedbacks/list-questions`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const feedbackCategoriesApi = async () => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/learning/categories/list`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const sendFeedbackApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post(`/feedbacks`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const listEditionsServicesApi = async () => {
    const token = localStorage.getItem("token");
    const response = await api.get(`list/editions/magazines`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const showEditionServiceApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`show/edition/magazine/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const listMessageChatApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/orders/${id}/chats`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const sendMessageChatApi = async (id, data) => {
    const token = localStorage.getItem("token");
    const response = await api.post(`/chat/order/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const getEditionServiceApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`/show/edition/magazine/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const finishEditionServiceApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.post(`finished/edition/service/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const sendRoundCorrectionApi = async (data, id) => {
    const token = localStorage.getItem("token");
    const response = await api.post(`orders/${id}/round-correction/send`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const receiveRoundCorrectionApi = async (data, id) => {
    const token = localStorage.getItem("token");
    const response = await api.post(`orders/${id}/round-correction/send`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const infoRoundCorrectionApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`orders/${id}/round-correction/info`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const finishRoundCorrectionApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.put(`finish/round-correction/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const createBudgetApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post(`budgets`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const approveBudgetApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.post(`budget/approve/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const listBudgetsApi = async () => {
    const token = localStorage.getItem("token");
    const response = await api.get(`list/budgets`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const getOrderStatus = async () => {
    const token = localStorage.getItem("token");
    const response = await api.get("/orders/status", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  }

  return (
    <OrderContext.Provider
      value={{
        listOrdersApi,
        createOrderApi,
        listExpertsStepsApi,
        getOrderInfo,
        listOrderStepsApi,
        answerOrderApi,
        listOrdersExpertApi,
        sendApproveOrderApi,
        approveOrderApi,
        feedbackQuestionsApi,
        feedbackCategoriesApi,
        sendFeedbackApi,
        getOrderFeedbackApi,
        showExpertOrderFeedbackApi,
        listEditionsServicesApi,
        showEditionServiceApi,
        listMessageChatApi,
        sendMessageChatApi,
        getEditionServiceApi,
        finishEditionServiceApi,
        finishRoundCorrectionApi,
        infoRoundCorrectionApi,
        receiveRoundCorrectionApi,
        sendRoundCorrectionApi,
        createBudgetApi,
        listBudgetsApi,
        approveBudgetApi,
        getOrderStatus,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};
