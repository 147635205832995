import React, { useContext, useState, useEffect } from "react";
import api from "../../services/api";
import Moment from "react-moment";
import { format } from "date-fns";
import {
  Box,
  Input,
  IconButton,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Text,
  Grid,
  GridItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useDisclosure,
  Button,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
} from "@chakra-ui/react";
import { OrderContext } from "../../contexts/Order";
import { UserContext } from "../../contexts/User";

export default function DetailsOrder({ typeOrder }) {
  const {
    getOrderInfo,
    approveOrderApi,
    finishRoundCorrectionApi,
    infoRoundCorrectionApi,
    receiveRoundCorrectionApi,
    sendRoundCorrectionApi,
  } = useContext(OrderContext);
  const { listExpertsApi, inviteExpertApi, listExpertsAvaliationApi } =
    useContext(UserContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState();
  const [expertID, setExpertID] = useState();
  const [listExperts, setListExperts] = useState();
  const [listExpertsAvaliation, setListExpertsAvaliation] = useState();
  const [openModalApprove, setOpenModalApprove] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openModalCorrection, setOpenModalCorrection] = useState(false);
  const [openModalReceiptCorrection, setOpenModalReceiptCorrection] =
    useState(false);
  const [roundCorrection, setRoundCorrection] = useState(null);
  const [docsEditor, setDocsEditor] = useState(null);
  const [docCorrection, setDocCorrection] = useState();
  const [qtyWordsCorrection, setqtyWordsCorrection] = useState();
  const [aditionalValueOrder, setaditionalValueOrder] = useState();
  const [aditionalValueExpert, setaditionalValueExpert] = useState();
  const [wordsDone, setWordsDone] = useState();
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [file, setFile] = useState(null);
  const [filesZip, setFilesZip] = useState(null);
  const [filesZipDone, setFilesZipDone] = useState(false);
  const [serviceType, setServiceType] = useState("");
  const [permissionId, setPermissionId] = useState("")

  const handleSendToZip = () => {
    setIsLoading(true);
    const formData = new FormData();

    // Adicionar cada arquivo individual ao FormData

    if (file) {
      if (Array.isArray(file)) {
        file.forEach((fileItem, index) => {
          formData.append(`file[${index}]`, fileItem);
        });
      } else {
        formData.append("file[0]", file);
      }
    }

    if (filesZip) {
      if (Array.isArray(filesZip)) {
        filesZip.forEach((zipFile, index) => {
          formData.append(`files_zip[${index}]`, zipFile);
        });
      } else {
        formData.append("files_zip[0]", filesZip);
      }
    }

    // Adicionar o orderID ao FormData
    const orderID = localStorage.getItem("orderID");
    formData.append("order_id", orderID);

    console.log(formData);
    const token = localStorage.getItem("token");
    // Enviar a requisição usando fetch ou a biblioteca de sua escolha (axios, por exemplo)
    fetch("http://localhost/provatis-api/public/api/files/round-correction", {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Resposta do servidor:", data);
        setFilesZipDone(true);
        toast({
          title:
            "Arquivos zipados, agora selecione o tipo de serviço e envie ao cliente",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error("Erro ao enviar a requisição:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOpenModalApprove = (index) => {
    setSelectedRow(index);
    setOpenModalApprove(true);
  };

  const handleCloseModalApprove = () => {
    setOpenModalApprove(false);
  };

  const handleOpenModalCorrection = (index) => {
    getDocumentsOrder();
    setSelectedRow(index);
    setOpenModalCorrection(true);
  };

  const handleCloseModalCorrection = () => {
    setOpenModalCorrection(false);
    // Limpar os estados quando o modal é fechado se necessário
    setDocCorrection("");
    setFile(null);
    setFilesZip(null);
    setFilesZipDone(false);
  };

  const handleOpenModalReceiptCorrection = (index) => {
    setSelectedRow(index);
    setOpenModalReceiptCorrection(true);
  };

  const handleCloseModalReceiptCorrection = () => {
    setOpenModalReceiptCorrection(false);
  };

  async function getOrderDetails() {
    const orderID = localStorage.getItem("orderID");

    try {
      const orderInfoApi = await getOrderInfo(orderID);
      setOrderDetails(orderInfoApi.data?.[0]);
      console.log(orderInfoApi.data?.[0]);
    } catch (error) {
      console.log(error);
    }
  }

  async function getListExperts(id) {
    const params = {
      permission: id,
    };

    setPermissionId(id)
    try {
      const listExpertsInfo = await listExpertsApi(params);
      setListExperts(listExpertsInfo.data?.[1]);
    } catch (error) {
      console.log(error);
      setListExperts(null);
    }
  }

  async function getListExpertsAvaliation() {
    try {
      const listExpertsInfo = await listExpertsAvaliationApi();
      setListExpertsAvaliation(listExpertsInfo.data?.[1]);
      console.log("experts em teste", listExpertsInfo);
    } catch (error) {
      console.log(error);
      setListExperts(null);
    }
  }

  async function getRoundCorrection() {
    const orderID = localStorage.getItem("orderID");

    try {
      const response = await infoRoundCorrectionApi(orderID);
      setRoundCorrection(response.data?.[0]);
    } catch (error) {
      console.log(error);
      setListExperts(null);
    }
  }

  async function handleSendToCorrection() {
    setIsLoading(true);
    const orderID = localStorage.getItem("orderID");

    const data = {
      order_id: orderID,
      service_type: serviceType,
    };
    try {
      const response = await sendRoundCorrectionApi(data);
      if (response.status === 200) {
        toast({
          title: "Enviado ao cliente com sucesso!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      getRoundCorrection();
    } catch (error) {
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
      console.log(error);
    } finally {
      setIsLoading(false);
      setOpenModalCorrection(false);
    }
  }

  async function handleReceiveCorrection() {
    const orderID = localStorage.getItem("orderID");

    const data = {
      order_id: orderID,
      qty_words: qtyWordsCorrection,
      value_aditional_order: aditionalValueOrder,
      value_aditional_expert: aditionalValueExpert,
    };
    try {
      const response = await receiveRoundCorrectionApi(data);
      if (response.status === 200) {
        toast({
          title: "Expert convidado com Sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      getRoundCorrection();
      onClose();
    } catch (error) {
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
      console.log(error);
    }
  }

  async function handleInviteExpert() {
    const orderID = localStorage.getItem("orderID");

    const data = {
      expert_role_id: permissionId,
      user_id: expertID,
      order_id: orderID,
    };
    try {
      const response = await inviteExpertApi(data);
      if (response.status === 200) {
        toast({
          title: "Expert convidado com Sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      onClose();
    } catch (error) {
      toast({
        title: "Deu algo errado",
        status: "error",
        isClosable: true,
      });
      console.log(error);
    }
  }

  async function approveExpert(value, answer) {
    const orderID = localStorage.getItem("orderID");

    const data = {
      order_id: orderID,
      user_id: value.user_id,
      answer: answer,
      words_done: value.words_done === wordsDone ? value.words_done : wordsDone,
    };

    const mensagem = "Você conferiu a quantidade de palavras?";
    const confirmacao = window.confirm(mensagem);

    if (confirmacao) {
      try {
        const response = await approveOrderApi(data, orderID);
        if (response.status === 200) {
          toast({
            title: "Resposta Enviada",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
        setOpenModalApprove(false);
        getOrderDetails();
      } catch (error) {
        toast({
          title: "Deu algo errado",
          status: "error",
          isClosable: true,
        });
        console.log(error);
      }
    } else {
      handleCloseModalApprove();
      // Código a ser executado se o usuário clicar em "Cancelar"
      console.log("Usuário cancelou a operação.");
    }
  }

  async function getDocumentsOrder() {
    const orderID = localStorage.getItem("orderID");
    const token = localStorage.getItem("token");
    try {
      const response = await api.get(`orders/${orderID}/documents/list`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDocsEditor(response.data?.[1]?.["editor"]);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getOrderDetails();
    getRoundCorrection();
    getListExpertsAvaliation();
  }, []);

  return (
    <>
      {orderDetails && (
        <>
          <Box
            bg={"#ffff"}
            borderRadius={"15px "}
            boxShadow={"0px 7px 16px 4px #EAEAEA"}
            p={5}
            ml={{
              base: 0, // Remove a margem em telas menores (base)
              md: "180px", // Define a margem em telas maiores (md)
            }}
            maxWidth={"100%"}
          >
            <Flex pb={5} borderBottom={"1px solid #D9D9D9"}>
              <Box>Detalhes do serviço</Box>
            </Flex>
            <Grid
              paddingTop={5}
              templateColumns="repeat(2, 1fr)"
              borderBottom={"1px solid  #D9D9D9"}
              paddingBottom={5}
              marginBottom={5}
            >
              <GridItem>
                <Text color={"#748298"} fontSize={"14px"} fontWeight={"light"}>
                  Título
                </Text>

                <Text fontSize={"16px"}> {orderDetails.title}</Text>
              </GridItem>
            </Grid>

            <Grid
              templateColumns="repeat(4, 1fr)"
              borderBottom={"1px solid  #D9D9D9"}
              paddingBottom={5}
              marginBottom={5}
            >
              <GridItem>
                <Text color={"#748298"} fontSize={"14px"} fontWeight={"light"}>
                  ID
                </Text>

                <Text> {orderDetails.id}</Text>
              </GridItem>

              <GridItem>
                <Text color={"#748298"} fontSize={"14px"} fontWeight={"light"}>
                  Plano
                </Text>
                {orderDetails?.plan_id === 1
                  ? "Basic"
                  : orderDetails?.plan_id === 2
                  ? "PRO"
                  : orderDetails?.plan_id === 3
                  ? "FULL"
                  : null}
              </GridItem>
              {typeOrder === "geral" && (
                <GridItem>
                  <Text
                    color={"#748298"}
                    fontSize={"14px"}
                    fontWeight={"light"}
                  >
                    Tipo de Documento
                  </Text>
                  <Text pl={3}>{orderDetails.type_document}</Text>
                </GridItem>
              )}

              <GridItem>
                <Text color={"#748298"} fontSize={"14px"} fontWeight={"light"}>
                  FastDelivery
                </Text>
                <Text pl={3}>
                  {orderDetails.fast_delivery === 0 ? "NÃO" : "SIM"}
                </Text>
              </GridItem>
            </Grid>

            {typeOrder === "geral" && (
              <Grid
                templateColumns="repeat(2, 1fr)"
                borderBottom={
                  orderDetails.description != null ? "1px solid #D9D9D9" : ""
                }
                paddingBottom={5}
                marginBottom={5}
              >
                <GridItem>
                  <Text
                    color={"#748298"}
                    fontSize={"14px"}
                    fontWeight={"light"}
                  >
                    Idioma origem do documento
                  </Text>

                  <Text>
                    {orderDetails.language_origin === "pt" ? "Português" : ""}
                  </Text>
                </GridItem>
                <GridItem>
                  <Text
                    color={"#748298"}
                    fontSize={"14px"}
                    fontWeight={"light"}
                  >
                    Idioma final do documento
                  </Text>
                  <Text pl={3}>
                    {orderDetails.language_final === "en" ? "Inglês" : ""}{" "}
                  </Text>
                </GridItem>
              </Grid>
            )}
            {orderDetails.description != null ? (
              <Grid>
                <GridItem>
                  <Text color={"#748298"} pb={15}>
                    Descrição
                  </Text>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<Text pl={3}>${orderDetails.description}</Text>`,
                    }}
                  />
                </GridItem>
              </Grid>
            ) : (
              ""
            )}
          </Box>

          <Box
            mt={5}
            bg={"#ffff"}
            borderRadius={"15px "}
            boxShadow={"0px 7px 16px 4px #EAEAEA"}
            p={5}
            mb={35}
            ml={{
              base: 0, // Remove a margem em telas menores (base)
              md: "180px", // Define a margem em telas maiores (md)
            }}
            maxWidth={"100%"}
          >
            <Text borderBottom={"1px solid  #D9D9D9"} pb={5}>
              Valor e Prazo
            </Text>
            <Grid templateColumns="repeat(3, 1fr)">
              <GridItem pt={2}>
                <Text color={"#748298"} fontSize={"14px"} fontWeight={"light"}>
                  Valor do serviço
                </Text>

                <Text>
                  R${" "}
                  {orderDetails?.price_estimated?.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  })}
                </Text>
              </GridItem>
              <GridItem
                display={"flex"}
                alignItems={"center"}
                fontSize={"12px"}
                pt={5}
              >
                  <>
                    <span style={{ marginRight: "10px" }}>Data de entrega</span>
                    <Moment format="DD-MM-YYYY">{orderDetails.details?.deadline}</Moment>
                  </>
                
              </GridItem>
            </Grid>
          </Box>

          <Box
            mt={5}
            bg="#ffff"
            borderRadius="15px"
            boxShadow="0px 7px 16px 4px #EAEAEA"
            p={5}
            mb={35}
            ml={{
              base: 0, // Remove a margem em telas menores (base)
              md: "180px", // Define a margem em telas maiores (md)
            }}
            maxWidth={"100%"}
          >
            <Flex
              justifyContent="space-between"
              borderBottom="1px solid #D9D9D9"
              pb={5}
            >
              <Box>
                <Text>Etapas</Text>
              </Box>
              <Box>
                <Button onClick={onOpen}>Convidar Expert</Button>
              </Box>
            </Flex>

            <Box overflowX="auto">
              <Table fontSize="12px">
                <Thead css={{ fontFamily: "Poppins", fontSize: "12px" }}>
                  <Tr>
                    <Th>Etapa</Th>
                    <Th>Expert</Th>
                    <Th>Status</Th>
                    <Th>Valor</Th>
                    <Th>Data Entrega</Th>
                    <Th>N. Palavras</Th>
                    <Th>Finalizado</Th>
                    <Th>Aprovado</Th>
                    <Th>Solicitado aprovação</Th>
                  </Tr>
                </Thead>
                <Tbody color="#888686" fontWeight={300} fontSize="10px">
                  {orderDetails?.historic?.map((index) => (
                    <Tr key={index.id}>
                      <Td>{index.permission}</Td>
                      <Td>{index.name}</Td>
                      <Td>{index.status}</Td>
                      <Td>
                        R${" "}
                        {index.value.toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                        })}
                      </Td>
                      <Td>{format(new Date(index.deadline), "dd/MM/yyyy")}</Td>
                      <Td>
                        {index.words_done === null ? 0 : index.words_done}
                      </Td>
                      <Td>{index.finished === 0 ? "Não" : "Finalizado"}</Td>
                      <Td>
                        {index.approved === 0
                          ? "Não"
                          : index.approved === 1
                          ? "Pendente"
                          : index.approved === 2
                          ? "Aprovado"
                          : ""}
                      </Td>
                      <Td>{index.finished_date ? format(new Date(index.finished_date), "yyyy-MM-dd") : ""}</Td>
                      {index.approved != 2 && (
                        <Td>
                          <Button
                            onClick={() => handleOpenModalApprove(index)}
                            fontSize={"12px"}
                          >
                            Aprovar Finalização
                          </Button>
                        </Td>
                      )}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </Box>

          {orderDetails.plan_id === 2 || orderDetails.plan_id === 3 ? (
            <>
              <Box
                bg={"#ffff"}
                borderRadius={"15px "}
                boxShadow={"0px 7px 16px 4px #EAEAEA"}
                p={5}
                ml={{
                  base: 0, // Remove a margem em telas menores (base)
                  md: "180px", // Define a margem em telas maiores (md)
                }}
                maxWidth={"100%"}
                mb={5}
              >
                <Text borderBottom={"1px solid  #D9D9D9"} pb={5}>
                  Rodada de correção
                </Text>
                <Box display={"inline-block"}>
                  <Button onClick={handleOpenModalCorrection} m={2}>
                    Enviar para rodada de correção
                  </Button>
                  <Button onClick={handleOpenModalReceiptCorrection} m={2}>
                    Recebimento rodada de correção
                  </Button>
                </Box>
                {roundCorrection != null ? (
                  <>
                    <Table>
                      <Thead>
                        <Tr>
                          <Td>Quantidade de palavras</Td>
                          <Td>Valor adicional do serviço</Td>
                          <Td>Valor adicional para o expert</Td>
                          <Td>Correção finalizado</Td>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>{roundCorrection?.qty_words_correct}</Td>
                          <Td>{roundCorrection?.price_round_correction}</Td>
                          <Td>{roundCorrection?.price_to_expert}</Td>
                          <Td>
                            {roundCorrection?.finish === true ? "SIM" : "NÃO"}
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </>
                ) : (
                  ""
                )}
              </Box>
            </>
          ) : null}

          <Box
            bg={"#ffff"}
            borderRadius={"15px "}
            boxShadow={"0px 7px 16px 4px #EAEAEA"}
            p={5}
            ml={{
              base: 0, // Remove a margem em telas menores (base)
              md: "180px", // Define a margem em telas maiores (md)
            }}
            maxWidth={"100%"}
          >
            <Text borderBottom={"1px solid  #D9D9D9"} pb={5}>
              {" "}
              Documentos
            </Text>

            <Grid p={5} templateColumns="repeat(3, 1fr)">
              <GridItem>
                <Text color={"#748298"} pt={15} pb={5}>
                  Número de palavras
                </Text>

                <Text pl={3}>{orderDetails.words_number_original}</Text>
              </GridItem>
              <GridItem>
                <Text color={"#748298"} pt={15} pb={5}>
                  Número de referências
                </Text>

                <Text pl={3}>{orderDetails.total_reference}</Text>
              </GridItem>
              <GridItem>
                <Text color={"#748298"} pt={15} pb={5}>
                  Quantidade de figuras
                </Text>

                <Text pl={3}>{orderDetails.qty_figure}</Text>
              </GridItem>
            </Grid>
            <Box p={5}>
              <Text color={"#748298"} pt={15} pb={5}>
                Abstract
              </Text>
              <div
                dangerouslySetInnerHTML={{
                  __html: `<Text pl={3}>${orderDetails.abstract}</Text>`,
                }}
              />
            </Box>
          </Box>

          {/* * Modal Invite  Expert */}

          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Convidar Expert</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Flex display={"inline-flex"}>
                  <FormControl width={"48%"}>
                    <FormLabel>Etapa</FormLabel>
                    <Select
                      placeholder="Selecione a etapa"
                      onChange={(e) => getListExperts(e.target.value)}
                    >
                      <option value={1}>Tradutor</option>
                      <option value={2}>Revisor</option>
                      <option value={3}>Editor</option>
                      <option value={4}>Editor de normas</option>
                    </Select>
                  </FormControl>
                  <FormControl ml={2} width={"48%"}>
                    <FormLabel>Usuarios</FormLabel>
                    <Select
                      placeholder="Selecione o expert"
                      onChange={(e) => setExpertID(e.target.value)}
                    >
                      {listExperts?.map((index) => (
                        <>
                          <option key={index.user.id} value={index.user.id}>
                            {index.user.name}
                          </option>
                        </>
                      ))}
                    </Select>
                  </FormControl>
                </Flex>
                <FormControl ml={2} width={"98%"} mt={5}>
                  <FormLabel>Usuarios em fase de Avaliação</FormLabel>
                  <Select
                    placeholder="Selecione o expert"
                    onChange={(e) => setExpertID(e.target.value)}
                  >
                    {listExpertsAvaliation?.map((index) => (
                      <>
                        <option key={index.userid} value={index.user.id}>
                          {index.user.name}
                        </option>
                      </>
                    ))}
                  </Select>
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={handleInviteExpert}>
                  Convidar
                </Button>
                <Button variant="ghost" onClick={onClose}>
                  Fechar
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {openModalApprove && selectedRow && (
            <Modal isOpen={openModalApprove} onClose={handleCloseModalApprove}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Solicitação finalização </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text>Etapa: {selectedRow.permission}</Text>
                  <Text>Expert: {selectedRow.name}</Text>
                  <Text>Quantidade de palavras: {selectedRow.words_done}</Text>
                  <Input
                    placeholder="Corrigir quantidade de palavras"
                    onChange={(e) => setWordsDone(e.target.value)}
                  ></Input>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => approveExpert(selectedRow, 1)}
                  >
                    Aprovar
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}

          {/* Modal Enviar de correção */}
          {openModalCorrection && (
            <Modal
              isOpen={openModalCorrection}
              onClose={handleCloseModalCorrection}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{orderDetails.title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text mb={5}>
                    Plano:{" "}
                    {orderDetails?.plan_id === 1
                      ? "Basic"
                      : orderDetails?.plan_id === 2
                      ? "PRO"
                      : orderDetails?.plan_id === 3
                      ? "FULL"
                      : null}
                  </Text>
                  <Text>Selecione os arquivos que devem ser zipados </Text>
                  {/* Campo de upload para arquivo zipado */}
                  <Input
                    type="file"
                    multiple
                    onChange={(e) => setFilesZip(e.target.files[0])}
                    mb={3}
                  />
                  {/* Campo de upload para arquivo individual */}
                  <Text>
                    Selecione os arquivos que serão enviados fora do zip{" "}
                  </Text>
                  <Input
                    type="file"
                    multiple
                    onChange={(e) => setFile(e.target.files[0])}
                    mb={3}
                  />

                  {filesZipDone == false ? (
                    <Button
                      colorScheme="green"
                      mr={3}
                      onClick={() => handleSendToZip()}
                      isLoading={isLoading}
                      loadingText="Zipando..."
                    >
                      Zipar Arquivos
                    </Button>
                  ) : (
                    ""
                  )}
                </ModalBody>
                {filesZipDone == true ? (
                  <ModalFooter>
                    <Select
                      mr={2}
                      placeholder="Selecione o tipo de serviço"
                      onChange={(e) => setServiceType(e.target.value)}
                    >
                      {orderDetails?.plan_id === 1 && (
                        <>
                          <option value="traducao">Tradução</option>
                          <option value="revisao">Revisão</option>
                        </>
                      )}
                      {orderDetails?.plan_id === 2 && (
                        <>
                          <option value="traducao">Tradução</option>
                          <option value="revisao">Revisão</option>
                          <option value="final">Final</option>
                        </>
                      )}
                      {orderDetails?.plan_id === 3 && (
                        <>
                          <option value="traducao">Tradução</option>
                          <option value="revisao">Revisão</option>
                          <option value="final">Final</option>
                        </>
                      )}
                    </Select>

                    <Button
                      colorScheme="green"
                      mr={3}
                      onClick={() => handleSendToCorrection()}
                      isLoading={isLoading}
                      loadingText="Enviando..."
                    >
                      Enviar
                    </Button>
                  </ModalFooter>
                ) : (
                  " "
                )}
              </ModalContent>
            </Modal>
          )}
          {/* Modal receber correção */}

          {openModalReceiptCorrection && (
            <Modal
              isOpen={openModalReceiptCorrection}
              onClose={handleCloseModalReceiptCorrection}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{orderDetails.title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text>
                    <b>Plano:</b>{" "}
                    {orderDetails?.plan_id === 1
                      ? "Basic"
                      : orderDetails?.plan_id === 2
                      ? "PRO"
                      : orderDetails?.plan_id === 3
                      ? "FULL"
                      : null}
                  </Text>
                </ModalBody>
                <Box m={2}>
                  <Input
                    mb={"5px"}
                    placeholder="Quantidade de palavras para correção"
                    type="number"
                    required
                    onChange={(e) => setqtyWordsCorrection(e.target.value)}
                  />

                  <Input
                    mb={"5px"}
                    placeholder="Valor adicional ao serviço"
                    type="number"
                    required
                    onChange={(e) => setaditionalValueOrder(e.target.value)}
                  />
                  <Input
                    mb={"5px"}
                    placeholder="Valor adicional ao expert"
                    type="number"
                    required
                    onChange={(e) => setaditionalValueExpert(e.target.value)}
                  />
                </Box>

                <ModalFooter>
                  <Button
                    colorScheme="green"
                    mr={3}
                    onClick={() => handleReceiveCorrection()}
                  >
                    Enviar
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
        </>
      )}
    </>
  );
}
