import React, { useState } from "react";
import api from "../services/api";

export const UserContext = React.createContext({});

export const UserProvider = ({ children }) => {
  const listClientsApi = async () => {
    const token = localStorage.getItem("token");
    const response = await api.get("clients", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const createClientApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("clients", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const listExpertsApi = async (params) => {
    const token = localStorage.getItem("token");
    const response = await api.get(
      "experts",
      { params },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  };

  const listExpertsAllApi = async (params) => {
    const token = localStorage.getItem("token");
    const response = await api.get(
      "experts",
      { params },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  };

  const listExpertsAvaliationApi = async () => {
    const token = localStorage.getItem("token");
    const params = { expert_test: 1 }; 
    const response = await api.get(
      "experts",
      { params },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  };

  const getUserInfoApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`users/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const getClientInfoApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`clients/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const inviteExpertApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("invites", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const listExpertsNotificationsApi = async () => {
    const token = localStorage.getItem("token");
    const response = await api.get("notifications", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const createExpertApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("experts", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const getExpertInfoApi = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`experts/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  const updateExpertApi = async (data, id) => {
    const token = localStorage.getItem("token");
    const response = await api.put(`experts/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };
  const updateClientApi = async (data, id) => {
    const token = localStorage.getItem("token");
    const response = await api.put(`clients/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const updateUserApi = async (data, id) => {
    const token = localStorage.getItem("token");
    const response = await api.put(`users/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const insertScheduleExpertApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("events", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  const listScheduleExpert = async (id) => {
    const token = localStorage.getItem("token");
    const response = await api.get(`users/${id}/events`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  };

  {/* const listExpertEditor = async () => {
    const token = localStorage.getItem("token");
    const response = await api.get("experts/editors/magazine", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  }; */}

  const inviteExpertEditionApi = async (data) => {
    const token = localStorage.getItem("token");
    const response = await api.post("invite/expert/edition", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json;charset=utf-8",
      },
    });
    return response;
  };

  return (
    <UserContext.Provider
      value={{
        listClientsApi,
        createClientApi,
        listExpertsApi,
        inviteExpertApi,
        listExpertsNotificationsApi,
        createExpertApi,
        getExpertInfoApi,
        updateExpertApi,
        updateClientApi,
        updateUserApi,
        getUserInfoApi,
        getClientInfoApi,
        insertScheduleExpertApi,
        listScheduleExpert,
       // listExpertEditor,
        inviteExpertEditionApi,
        listExpertsAvaliationApi,
        listExpertsAllApi,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
