import React, { useContext, useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";
import {
  Box,
  Container,
  Flex,
  Text,
  Spacer,
  useDisclosure,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TabIndicator,
  Input,
  Select,
  NumberInputField,
  NumberInput,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputStepper,
} from "@chakra-ui/react";
import { OrderContext } from "../../contexts/Order";
import {
  MdEditSquare,
  MdRemoveRedEye,
  MdOutlineTextSnippet,
} from "react-icons/md";
import { SearchIcon } from "@chakra-ui/icons";

export default function Orders() {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const { listOrdersApi, listOrdersExpertApi, getOrderStatus } =
    useContext(OrderContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [orders, setOrders] = useState([]);
  const [orderPreview, setOrderPreview] = useState();
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [typeOrder, setTypeOrder] = useState("general");
  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const [deadline, setDeadline] = useState("");
  const [status, setStatus] = useState("");
  const [statusList, setStatusList] = useState([]);
  

  function limitToTwoWords(text) {
    if (typeof text !== 'string') {
      return ''; // Return an empty string or handle the case as needed
  }
    const words = text.split(" ");
    
    if (words.length > 2) {
      return words.slice(0, 2).join(" ") + "..."; // Adiciona '...' se tiver mais de 2 palavras
    }
    return text;
  }

  function handleEditService(index) {
    localStorage.setItem("orderID", index.orderID);
    const type = typeOrder === "general" ? "geral" : "edicao-normas";
    if (user.role_id === 1) {
      navigate(`/servico/${index.id}/${type}`);
    } else {
      navigate(`/expert/servico/${index.id}/${type}`);
    }
  }

  // Função para carregar pedidos com base na página fornecida
  async function loadOrders(page) {
    
    try {
      let params = {
        page,
      };
     
      if (typeOrder) {
        params.type_service = typeOrder;
      }

      if (title) {
        params.title = title;
      }

      if (id) {
        params.id = id;
      }

      if (deadline) {
        params.deadline = deadline;
      }

      if (status) {
        params.status = status;
      }

      const ordersApi =
        user.role_id === 1
          ? await listOrdersApi(params)
          : await listOrdersExpertApi(params);
      setOrders(ordersApi?.data?.[0]?.data || []);
      setLastPage(ordersApi?.data?.[0]?.last_page || 1);
      console.log("IDS", ordersApi);
      if (page !== currentPage) {
        setCurrentPage(page);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }

  function previewOrder(index) {
    if (isPreviewing) {
      return;
    }

    setIsPreviewing(true);
    setOrderPreview(index);

    onOpen();

    setTimeout(() => {
      setIsPreviewing(false);
    }, 1000); // Aguardar 1 segundo antes de permitir uma nova chamada
  }

  function handleChangeTab(index) {
    setCurrentPage(1);
    if (index === 0) {
      setTypeOrder("general");

      return;
    }

    setTypeOrder("edit-norms");
  }

  async function getStatus() {
    const statusList = await getOrderStatus();
    setStatusList(statusList?.data?.[0]);
  }

  useEffect(() => {
    getStatus();
  }, []);

  useEffect(() => {
    loadOrders(currentPage);
  }, [currentPage, typeOrder]);

  return (
    <>
      <Navbar />

      <Container
        position={"absolute"}
        top={120}
        ml={{
          base: 0, // Remove a margem em telas menores (base)
          md: 270, // Define a margem em telas maiores (md)
        }}
        minWidth={"78vw"}
      >
        <Box
          bg={"#ffff"}
          borderRadius={"15px 15px 0 0"}
          boxShadow={"0px 7px 16px 0px #EAEAEA"}
        >
          <Flex display={"flex"} padding={5} alignItems="center">
            <Box display={"flex"} alignItems={"center"}>
              <MdOutlineTextSnippet color="#04D194" size={"24px"} />
              <Text
                marginInline={"0.5rem"}
                fontSize={"24px"}
                fontWeight={"400"}
              >
                Serviços
              </Text>
            </Box>

            <Spacer />

            {/* {user.role_id === 1 ? (
              <>
                <Box mt={2}>
                  <Button colorScheme="green" fontSize={"14px"}>
                    <Link to="/editions/servicos">
                      {" "}
                      Ver Edições das normas da revista{" "}
                    </Link>
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Box mt={2}>
                  <Button fontSize={"14px"} colorScheme="green">
                    <Link to="/editions/servicos/expert">
                      {" "}
                      Ver Edições das normas da revista{" "}
                    </Link>
                  </Button>
                </Box>
              </>
            )} */}

            <Spacer />

            {user.role_id === 1 ? (
              <>
                <Box>
                  <Button
                    colorScheme="green"
                    fontSize={"14px"}
                    _hover={{
                      backgroundColor: "#33BA9D",
                    }}
                  >
                    <Link
                      to={`/adicionar/servico/${
                        typeOrder === "general" ? "geral" : "edicao-normas"
                      }`}
                    >
                      {`Adicionar novo serviço ${
                        typeOrder === "general"
                          ? "geral"
                          : "de edição de normas"
                      }`}
                    </Link>
                  </Button>
                </Box>
              </>
            ) : (
              ""
            )}
          </Flex>
        </Box>

        <Tabs onChange={(index) => handleChangeTab(index)}>
          <Box bg={"#ffff"} boxShadow={"0px 7px 16px 4px #EAEAEA"}>
            <TabList>
              <Tab _selected={{ color: "#04D194" }}>Serviços gerais</Tab>
              <Tab _selected={{ color: "#04D194" }}>Edição de normas</Tab>
            </TabList>
            <TabIndicator
              mt="-1.5px"
              height="2px"
              bg="#04D194"
              borderRadius="2px"
            />
          </Box>

          <Box
            bg={"#ffff"}
            borderRadius={"0 0 15px 15px"}
            boxShadow={"0px 7px 16px 4px #EAEAEA"}
            padding={"2rem"}
          >
            <Text fontSize={"20px"} fontWeight={"400"}>
              Busca e Filtros
            </Text>

            <Box
              marginTop={"1rem"}
              display={"flex"}
              width={"100%"}
              alignItems={"center"}
              justifyContent={"flex-start"}
            >
              <Box maxWidth={"12rem"} marginRight={'2rem'}>
                <Text fontSize={"14px"} fontWeight={"400"}>
                  Título
                </Text>
                <Input
                  variant="outline"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Box>

              <Box width={"12rem"} marginRight={'2rem'}>
                <Text fontSize={"14px"} fontWeight={"400"}>
                  ID do serviço
                </Text>
                <NumberInput
                  value={id}
                  onChange={(newId) => setId(newId)}
                  variant="outline"
                  placeholder="Digite um ID do serviço"
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Box>

              <Box width={"12rem"} marginRight={'2rem'}>
                <Text fontSize={"14px"} fontWeight={"400"}>
                  Prazo final
                </Text>
                <Input
                  type="date"
                  variant="outline"
                  placeholder="Digite uma prazo final"
                  value={deadline}
                  onChange={(e) => setDeadline(e.target.value)}
                />
              </Box>

              {user.role_id === 1 && (
                <Box width={"12rem"} marginRight={'2rem'}>
                  <Text fontSize={"14px"} fontWeight={"400"}>
                    Status
                  </Text>
                  <Select
                    variant="outline"
                    placeholder=" "
                    onChange={($event) => setStatus($event.target.value)}
                  >
                    {statusList.map((statusItem) => (
                      <option value={statusItem.id}>{statusItem.status}</option>
                    ))}
                  </Select>
                </Box>
              )}

              <Button
                marginTop={"1rem"}
                colorScheme="gray"
                fontSize={"14px"}
                leftIcon={<SearchIcon />}
                onClick={() => loadOrders(1)}
              >
                Buscar
              </Button>
            </Box>
          </Box>

          <TabPanels>
            <TabPanel>
              <Box>
                <Box
                  backgroundColor={"white"}
                  mt={5}
                  boxShadow={"0px 8px 16px -10px"}
                  borderRadius={"8px"}
                  marginBottom={5}
                >
                  <TableContainer>
                    <Table fontSize={"12px"}>
                      <Thead css={{ fontFamily: "Poppins", fontSize: "12px" }}>
                        <Tr>
                          <Th>ID</Th>
                          {user.role_id === 1 ? <Th>Cliente</Th> : ""}

                          <Th>Titulo</Th>
                          {user.role_id === 1 ? <Th>Expert</Th> : ""}

                          <Th>Status</Th>
                          <Th>Etapa</Th>
                          <Th>Plano</Th>
                          <Th>Tipo de Documento</Th>
                          <Th>Prazo Final</Th>
                        </Tr>
                      </Thead>
                      <Tbody color={"#888686"} fontWeight={"300"}>
                        {orders.length > 0 &&
                          orders.map((index) => (
                            <Tr key={index.id}>
                              <Td>{index.id}</Td>
                              {user.role_id === 1 ? (
                                <Td>{limitToTwoWords(index.user.name)}</Td>
                              ) : (
                                ""
                              )}

                              <Td noOfWords={5}>
                                {limitToTwoWords(index.title)}
                              </Td>
                              {user.role_id === 1 ? (
                                <Td>{index.order_expert_historics[0]?.user?.name}</Td>
                              ) : (
                                ""
                              )}
                              <Td>{index.status.status}</Td>
                              <Td>{index.step.step}</Td>
                              <Td>{index.plan.name}</Td>
                              <Td>{index.type_document}</Td>
                              <Td>
                                {format(parseISO(index.deadline), "dd/MM/yyyy")}
                              </Td>
                              <Td>
                                <Box display={"inline-flex"} fontSize={"16px"}>
                                  <Box
                                    cursor={"pointer"}
                                    color={"#115EFA"}
                                    onClick={() => handleEditService(index)}
                                  >
                                    <MdEditSquare />
                                  </Box>
                                  <Box ml={2} color={"#00FF94"}>
                                    <MdRemoveRedEye
                                      onClick={() => previewOrder(index)}
                                      cursor={"pointer"}
                                    />
                                  </Box>
                                </Box>
                              </Td>
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box>
                  <Button
                    colorScheme="green"
                    onClick={() => goToPreviousPage(currentPage - 1)}
                    disabled={currentPage === 1}
                    fontSize={"12px"}
                  >
                    &laquo; Anterior
                  </Button>
                  <Button
                    colorScheme="green"
                    ml={2}
                    onClick={() => goToNextPage(currentPage + 1)}
                    disabled={currentPage === lastPage}
                    fontSize={"12px"}
                  >
                    Próxima &raquo;
                  </Button>
                </Box>
              </Box>
            </TabPanel>
            <TabPanel>
              <Box>
                <Box
                  backgroundColor={"white"}
                  mt={5}
                  boxShadow={"0px 8px 16px -10px"}
                  borderRadius={"8px"}
                  marginBottom={5}
                >
                  <TableContainer>
                    <Table fontSize={"12px"}>
                      <Thead css={{ fontFamily: "Poppins", fontSize: "12px" }}>
                        <Tr>
                          <Th>ID</Th>
                          {user.role_id === 1 ? <Th>Cliente</Th> : ""}

                          <Th>Titulo</Th>
                          {user.role_id === 1 ? <Th>Expert</Th> : ""}

                          <Th>Status</Th>
                          <Th>Etapa</Th>
                          <Th>Plano</Th>
                          <Th>Tipo de Documento</Th>
                          <Th>Prazo Final</Th>
                        </Tr>
                      </Thead>
                      <Tbody color={"#888686"} fontWeight={"300"}>
                        {orders.length > 0 &&
                          orders.map((index) => (
                            <Tr key={index.orderID}>
                              <Td>{index.orderID}</Td>
                              {user.role_id === 1 ? (
                                <Td>{limitToTwoWords(index.client)}</Td>
                              ) : (
                                ""
                              )}

                              <Td noOfWords={5}>
                                {limitToTwoWords(index.title)}
                              </Td>
                              {user.role_id === 1 ? (
                                <Td>{index.expert}</Td>
                              ) : (
                                ""
                              )}
                              <Td>{index.status.status}</Td>
                              <Td>{index.step.step}</Td>
                              <Td>{index.plan.name}</Td>
                              <Td>{index.type_document}</Td>
                              <Td>
                                {format(parseISO(index.deadline), "dd/MM/yyyy")}
                              </Td>
                              <Td>
                                <Box display={"inline-flex"} fontSize={"16px"}>
                                  <Box
                                    cursor={"pointer"}
                                    color={"#115EFA"}
                                    onClick={() => handleEditService(index)}
                                  >
                                    <MdEditSquare />
                                  </Box>
                                  <Box ml={2} color={"#00FF94"}>
                                    <MdRemoveRedEye
                                      onClick={() => previewOrder(index)}
                                      cursor={"pointer"}
                                    />
                                  </Box>
                                </Box>
                              </Td>
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box>
                  <Button
                    colorScheme="green"
                    onClick={() => goToPreviousPage(currentPage - 1)}
                    disabled={currentPage === 1}
                    fontSize={"12px"}
                  >
                    &laquo; Anterior
                  </Button>
                  <Button
                    colorScheme="green"
                    ml={2}
                    onClick={() => goToNextPage(currentPage + 1)}
                    disabled={currentPage === lastPage}
                    fontSize={"12px"}
                  >
                    Próxima &raquo;
                  </Button>
                </Box>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Container>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Preview do Serviço</ModalHeader>
          <ModalCloseButton />
          {orderPreview && (
            <ModalBody>
              <Text fontSize={"10px"}>Titulo do Serviço</Text>
              <Text>{orderPreview.title}</Text>

              <Box marginBlock={"0.5rem"}>
                <Text fontSize={"10px"}>Tipo do Serviço</Text>
                <Text>
                  {orderPreview.type_service === "general"
                    ? "Geral"
                    : "Edição de Norma"}
                </Text>
              </Box>

              <Box display={"inline-flex"} mt={5}>
                <Box>
                  <Text fontSize={"14px"}>{orderPreview.status.status}</Text>
                </Box>
                <Box ml={5}>
                  <Text fontSize={"14px"}>
                    Prazo Final: {format(parseISO(orderPreview.deadline), "dd/MM/yyyy")}
                  </Text>
                </Box>
              </Box>
              <Box mt={5} fontSize={"14px"} display={"inline-flex"}>
                <Box ml={2}>
                  <Text>ID: {orderPreview.orderID}</Text>
                </Box>
                <Box ml={2}>
                  <Text>| Plano: {orderPreview.plan.name}</Text>
                </Box>
                <Box ml={2}>
                  <Text> | Etapa: {orderPreview.step.step}</Text>
                </Box>
              </Box>
            </ModalBody>
          )}

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
